<template>
  <div class="case_video_container w">
    <!-- 咨询团队介绍 -->
    <div class="recommendPage">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="item in videoItemList" :key="item.id">
          <CaseVideoItem
            :title="item.title"
            :isTop="item.isTop"
            :pictrue="item.pictrue"
            :bgColor="item.bgColor"
            :fontSize="item.fontSize"
            :id="item.id"
          ></CaseVideoItem>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <div class="mask">
        <CaseMask></CaseMask>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import CaseMask from './case_mask.vue'
import CaseVideoItem from './case_video_item.vue'
export default {
  name: 'CaseDetail',
  components: {
    swiper,
    swiperSlide,
    CaseMask,
    CaseVideoItem
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        slidesPerView: 4,
        // spaceBetween: 30,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        watchSlidesProgress: true,
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      videoItemList: [
        {
          id: 1,
          title: '泉邦家族落地成果',
          isTop: true,
          bgColor: '#1651DD',
          fontSize: 40,
          pictrue: require('@/assets/images/case/case_landing.png')
        },
        {
          id: 2,
          title: '2019泉邦家族客户采访',
          isTop: false,
          bgColor: '#010343',
          fontSize: 32,
          pictrue: require('@/assets/images/case/case_interview.png')
        },
        {
          id: 3,
          title: '泉邦家族落地成果',
          isTop: false,
          bgColor: '#010343',
          fontSize: 28,
          pictrue: require('@/assets/images/case/case_yang.png')
        },
        {
          id: 4,
          title: '裂变式盈利客户见证--杨敏',
          isTop: false,
          bgColor: '#010343',
          fontSize: 28,
          pictrue: require('@/assets/images/case/case_yang.png')
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.case_video_container {
  padding: 153px 139px 0 181px;
  margin-bottom: 193px;
}
::v-deep .recommendPage {
  position: relative;
  width: 1600px;
  height: 800px;
  overflow: hidden;
  .swiper-slide {
    width: 400px;
    height: 800px;
  }
  .swiper-button-prev {
    width: 85px;
    height: 85px;
    background: #d8d8d8;
    opacity: 0.46;
    background: url(~@/assets/images/case/case_prev_btn.png) no-repeat;
    background-size: 100%;
  }
  .swiper-button-next {
    width: 85px;
    height: 85px;
    background: #d8d8d8;
    opacity: 0.46;
    background: url(~@/assets/images/case/case_next_btn.png) no-repeat;
    background-size: 100%;
  }
  .mask {
    position: absolute;
    top: 0px;
    left: 800px;
    z-index: 2222;
  }
}
</style>
