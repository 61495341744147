<template>
  <div class="case_item_container">
    <div class="img" v-if="isTop">
      <img :src="pictrue" alt="" />
    </div>
    <div class="content">
      <h3 class="title" :style="{ fontSize: fontSize + 'px' }">{{ title }}</h3>
      <div class="under_line"></div>
      <h4 class="share">智囊团分享</h4>
      <p>
        <slot> </slot>
      </p>
    </div>
    <div class="img" v-if="!isTop">
      <img :src="pictrue" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CaseItem',
  props: {
    title: {
      type: String,
      required: true
    },
    isTop: {
      type: Boolean,
      default: true
    },
    pictrue: {
      type: String,
      required: true
    },
    fontSize: {
      type: Number,
      default: 32
    }
  }
}
</script>

<style lang="less" scoped>
.case_item_container {
  color: #ffffff;
  cursor: pointer;
  .img {
    width: 400px;
    height: 400px;
  }
  &:hover {
    .content {
      background-color: #1651dd;
    }
  }
  .content {
    padding: 27px 23px 21px;
    width: 400px;
    height: 400px;
    background-color: #010343;
    .title {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 3px;
    }
    .under_line {
      width: 100%;
      border: 1px solid #ffffff;
      margin-bottom: 3px;
    }
    .share {
      font-size: 21px;
      font-weight: 300;
      line-height: 29px;
      opacity: 0.8;
      margin-bottom: 24px;
    }
    p {
      font-size: 21px;
      font-weight: 400;
      line-height: 29px;
      overflow: hidden; // 超出的文本隐藏
      text-overflow: ellipsis; // 溢出用省略号显示
      white-space: warp; // 溢出不换行
      display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
      -webkit-line-clamp: 6; // 这个属性不是css的规范属性，需要组合上面两个属性，表示显示的行数。
      -webkit-box-orient: vertical; // 从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）
    }
  }
}
</style>
