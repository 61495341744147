<template>
  <div class="case_mask_container">
    <h3 class="title">泉邦案例详情</h3>
    <div class="under_line"></div>
    <h3 class="en_title">QuanbangGroup Case</h3>
    <div class="content">
      <p>“一招鲜吃遍天”的时代早已一去不返</p>
      <p>紧随时代的大势才能立于不败之地</p>
      <p>商业唯一不变的本质</p>
      <p>就是不停地改变</p>
      <p>改变传统的思维、固化的观念、老旧的模式</p>
      <p>去拥抱不断变化的商业浪潮</p>
      <br />
      <p>传统行业如何适应互联网时代</p>
      <p>迷雾重重怎样才能抓住商机</p>
      <p>弯道超车 从不是痴人说梦</p>
      <p>因为先行者已踏出一片天地</p>
      <br />
      <p>
        经过泉邦服务的企业几乎涵盖全部业态，包括美业、服务业、健康行业、零售行业、服装行业等等。在各行各业都取得超出预期的成效，客户见证和反馈说明了泉邦的专业性，只要给予信任，我们必不负期待。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CaseMask'
}
</script>

<style lang="less" scoped>
.case_mask_container {
  width: 400px;
  height: 800px;
  background-color: #1651dd;
  padding-top: 41px;
  .title {
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 44px;
    margin-left: 37px;
  }
  .under_line {
    margin-left: 32px;
    border-bottom: 2px solid #ffffff;
  }
  .en_title {
    font-size: 24px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #ffffff;
    line-height: 33px;
    margin-left: 37px;
  }
  .content {
    margin-top: 21px;
    padding: 0 19px 0 24px;
    font-size: 21px;
    font-weight: 400;
    color: #ffffff;
    line-height: 29px;
  }
}
</style>
