<template>
  <div class="case_container">
    <!-- 顶部导航占位 -->
    <QbTopnavPlaceholder></QbTopnavPlaceholder>

    <!-- 公司案例标题 -->
    <QbTitle :title="title" :complement="complement"></QbTitle>
    <!-- 泉邦案例详情 -->
    <CaseDetail></CaseDetail>
    <!-- 泉邦视频案例 -->
    <CaseVideo></CaseVideo>
  </div>
</template>

<script>
import CaseDetail from './components/case_detail.vue'
import CaseVideo from './components/case_video.vue'
export default {
  name: 'case',
  components: {
    CaseDetail,
    CaseVideo
  },
  data() {
    return {
      title: '泉邦案例',
      complement: '饮水思泉  立业兴邦'
    }
  }
}
</script>

<style lang="less" scoped></style>
