<template>
  <div class="case_detail_container w">
    <!-- 咨询团队介绍 -->
    <div class="recommendPage">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="item in caseItemList" :key="item.id">
          <CaseItem
            :title="item.title"
            :bgColor="item.bgColor"
            :pictrue="item.pictrue"
            :isTop="item.isTop"
            :fontSize="item.fontSize"
            @click.native="$router.push('case/article/' + 'item.id')"
          >
            {{ item.content }}
          </CaseItem>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <div class="mask">
        <CaseMask></CaseMask>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import CaseMask from './case_mask.vue'
import CaseItem from './case_item.vue'
export default {
  name: 'CaseDetail',
  components: {
    swiper,
    swiperSlide,
    CaseMask,
    CaseItem
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        slidesPerView: 4,
        // spaceBetween: 30,
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        watchSlidesProgress: true,
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      caseItemList: [
        {
          id: 1,
          title: '易先生',
          pictrue: require('@/assets/images/case/case_yi.png'),
          isTop: true,
          fontSize: 48,
          content:
            '我创业13年一路走来起起伏伏，有的产业成功了，有的以失败收场，尤其是在2014年，一年的时间关掉三家公司，终于让我意识到学习的重要性。于是我开始四处游学，求取真经，寻求变革之法。'
        },
        {
          id: 2,
          title: '易先生',
          pictrue: require('@/assets/images/case/case_yi.png'),
          isTop: true,
          fontSize: 48,
          content:
            '我创业13年一路走来起起伏伏，有的产业成功了，有的以失败收场，尤其是在2014年，一年的时间关掉三家公司，终于让我意识到学习的重要性。于是我开始四处游学，求取真经，寻求变革之法。'
        },
        {
          id: 3,
          title: '王倩',
          pictrue: require('@/assets/images/case/case_wang.png'),
          isTop: true,
          fontSize: 48,
          content:
            '进入泉邦第一个学习的是股东招募，以很快的速度招到第一、二批股东60人，真正打开了一条财富之路，150平的店投资最多只要80万，但是一天可以收回成本156万，而且每天群里都有战报雨。从背后可以看到我们团队的凝聚力、士气，我的格局也被拉大了。'
        },
        {
          id: 4,
          title: '卓颜僮颜皮肤管理中心',
          pictrue: require('@/assets/images/case/case_zhuoyan.png'),
          isTop: false,
          fontSize: 32,
          content:
            '创业将近十年，一直从事服装行业都很顺利，后来开皮肤管理店的时候因为听信了不专业的指导投资了两百万进去，在开了十家店后发现因为缺乏经验，团队经营都跟不上。…'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.case_detail_container {
  padding: 172px 139px 0 181px;
}
::v-deep .recommendPage {
  position: relative;
  width: 1600px;
  height: 800px;
  overflow: hidden;
  .swiper-slide {
    width: 400px;
    height: 800px;
  }
  .swiper-button-prev {
    width: 85px;
    height: 85px;
    background: #d8d8d8;
    opacity: 0.46;
    background: url(~@/assets/images/case/case_prev_btn.png) no-repeat;
    background-size: 100%;
  }
  .swiper-button-next {
    width: 85px;
    height: 85px;
    background: #d8d8d8;
    opacity: 0.46;
    background: url(~@/assets/images/case/case_next_btn.png) no-repeat;
    background-size: 100%;
  }
  .mask {
    position: absolute;
    top: 0px;
    left: 400px;
    z-index: 2222;
  }
}
</style>
