<template>
  <div class="case_video_item_container">
    <div class="video_cover" v-if="isTop">
      <img class="cover_img" :src="pictrue" alt="" />
      <div class="paly_btn" @click="goPageVideo"></div>
    </div>
    <div class="title">
      <h3 :style="{ fontSize: fontSize + 'px' }">{{ title }}</h3>
      <div class="under_line"></div>
    </div>
    <div class="video_cover" v-if="!isTop">
      <img class="cover_img" :src="pictrue" alt="" />
      <div class="paly_btn" @click="goPageVideo"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    id: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isTop: {
      type: Boolean,
      default: true
    },
    pictrue: {
      type: String,
      required: true
    },
    fontSize: {
      type: Number,
      default: 28
    }
  },
  methods: {
    goPageVideo() {
      this.$router.push(`/case/video/${this.id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.case_video_item_container {
  &:hover {
    .title {
      background-color: #1651dd;
    }
  }
  .video_cover {
    position: relative;
    width: 400px;
    height: 637px;
    overflow: hidden;

    .paly_btn {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);
      width: 133px;
      height: 133px;
      background-image: url(~@/assets/images/case/case_btn.png);
      background-size: 100%;
      cursor: pointer;
      display: none;
    }

    &:hover .paly_btn {
      display: block;
      cursor: pointer;
    }
  }
  .title {
    width: 400px;
    height: 163px;
    padding-top: 57px;
    padding-left: 23px;
    background-color: #010343;
    h3 {
      font-size: 28px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 4px;
    }
    .under_line {
      width: 355px;
      border: 1px solid #ffffff;
    }
  }
}
</style>
